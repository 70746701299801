/* Styling the bar itself */

#topbar {
  position: absolute;
  top: 0;
  left: 50%;
  right: auto;
  display: block;
  z-index: 99999;
  box-sizing: border-box;
  width: auto;
  padding: 8px;
  background-color: #158830;
  color: rgb(250, 250, 250);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  transition: bottom 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  transform: translate(-50%);
}

#topbar.error {
  background-color: #9d0606;
}

/* Hiding the checkbox */

#toggleTop {
  display: none;
}
/* Styling the lables, to show and hide the bar */

#showTop:hover {
  background-color: rgba(158, 158, 158, 0.2);
  color: rgba(0, 0, 0, 0.8);
}

.hideTop {
  float: right;
  display: inline-block;
  cursor: pointer;
  padding: 2px 6px 2px 15px;
  font-size: 22px;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  line-height: 10px;
}

.hideTop:hover {
  background-color: rgba(158, 158, 158, 0.2);
}
/* Displaying the bar when the checkbox is checked */

#toggleTop:checked + #topbar {
  transition: bottom 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  display: none;
}